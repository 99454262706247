import React, {
  ChangeEventHandler,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as FormData from 'form-data';
import { ImSpinner11 } from 'react-icons/im';
import { GoFileSymlinkFile } from "react-icons/go";
import { FaLink, FaPencilAlt, FaPenSquare, FaEye } from 'react-icons/fa';
import { MdOutlineCancel, MdAttachFile } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

import { api } from '../../../../services/api';
import { ExportToExcel } from '../../../../components/ExcelExport';
import lupaBusca from '../../../../assets/icons/magnifying-glass.svg';
import * as XLSX from "xlsx";
import Layout from '../../../../components/Admin/Layout';
import { PartnersType } from '../Create';
import { useAuth } from '../../../../hooks/useAuth';
import { useCopyToClipboard } from '../../../../utils/helpers';
import { Proposal } from '../../../../interfaces/proposal.interface';
import './styles.scss';

type StatusAlertType = {
  status: string;
  message: ReactElement | string;
};

enum ModalType {
  CANCEL = 'cancel',
  ADD_FILE = 'addFile',
  INDEX_FILE = 'indexFile',
}

type ProposalFilesType = {
  uuid: string;
  name: string;
  originalName: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
};

export default function IndexFgts() {
  const { user } = useAuth();
  const isFirstRender = useRef(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTr, setLoadingTr] = useState<boolean | string>(false);
  const [searchValue, setSearchValue] = useState<null | string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [formattedStartDate, setFormattedStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [formattedEndDate, setFormattedEndDate] = useState<string>('');
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const regexMoney = /\d(?=(\d{3})+,)/g;
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const fileName = 'Propostas Realizadas';
  const [partnerUuid, setPartnerUuid] = useState<string>('');
  const [partners, setPartners] = useState<PartnersType[]>([]);
  const [exportData, setExportData] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<boolean>(false);
  const [newProposalStatus, setnewProposalStatus] = useState<string>('');
  const [actualProposal, setActualProposal] = useState<Proposal | null>(null);
  const [getLoansInfo, setGetLoansInfo] = useState<boolean>(false);
  const [pendingStatusType, setPendingStatusType] = useState<string>('');
  const [pendingProposalUuid, setPendingProposalUuid] = useState<string>('');
  const [documentTypeOne, setDocumentTypeOne] = useState<string>('');
  const [documentTypeTwo, setDocumentTypeTwo] = useState<string>('');
  const [pendingFileOne, setPendingFileOne] = useState<File | null>(null);
  const [pendingFileTwo, setPendingFileTwo] = useState<File | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [integrations, setIntegrations] = useState<any>([]);
  const [loadingExcelData, setLoadingExcelData] = useState<boolean>(false);
  const [excelData, setExcelData] = React.useState([]);
  const [actualProposalStatus, setActualProposalStatus] = useState<string>('');
  const [formalizationLink, setFormalizationLink] = useState<string>('');
  const statusProposal = {
    sketch: 'Rascunho',
    created: 'Criado',
    pending: 'Pendente',
    analysis: 'Em análise',
    pendingFormalizationSignature: 'Pendente formalização - Assinatura',
    pendingFormalizationSelfie: 'Pendente formalização - Selfie',
    pendingSubmissionDocuments: 'Pendente de envio de documentos',
    pendingResubmissionDocuments: 'Pendente reapresentação de documentos',
    progress: 'Em processo',
    completed: 'Concluído',
    canceled: 'Cancelado',
    reproved: 'Reprovado',
  };
  const proposalStatus = [
    {
      id: '',
      name: 'Selecione...',
    },
    {
      id: 'sketch',
      name: 'Rascunho',
    },
    {
      id: 'created',
      name: 'Criado',
    },
    {
      id: 'pending',
      name: 'Pendente',
    },
    {
      id: 'pendingFormalizationSignature',
      name: 'Pendente formalização - Assinatura',
    },
    {
      id: 'pendingFormalizationSelfie',
      name: 'Pendente formalização - Selfie',
    },
    {
      id: 'pendingSubmissionDocuments',
      name: 'Pendente de envio de documentos',
    },
    {
      id: 'pendingResubmissionDocuments',
      name: 'Pendente reapresentação de documentos',
    },
    {
      id: 'progress',
      name: 'Em processo',
    },
    {
      id: 'completed',
      name: 'Concluído',
    },
    {
      id: 'canceled',
      name: 'Cancelado',
    },
    {
      id: 'reproved',
      name: 'Reprovado',
    },
  ];
  const [contractNumber, setContractNumber] = useState<string>('');
  const [clientName, setClientName] = useState<string>('');
  const [clientCpf, setClientCpf] = useState<string>('');
  const [clientBirthDate, setClientBirthDate] = useState<string>('');
  const [clientEmail, setClientEmail] = useState<string>('');
  const [clientPhone, setClientPhone] = useState<string>('');
  const [integrationFriendlyName, setIntegrationFriendlyName] =
    useState<string>('');
  const [partnerFriendlyName, setPartnerFriendlyName] = useState<string>('');
  const [createdByConsultant, setCreatedByConsultant] = useState<string>('');
  const [proposalValue, setProposalValue] = useState<string>('');
  const [installment, setInstallment] = useState<string>('');
  const [proposalStatusRow, setProposalStatusRow] = useState<string>('');
  const [protocolId, setProtocolId] = useState<string>('');
  const [value, copy] = useCopyToClipboard();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reasonCancellation, setReasonCancellation] = useState<string>('');
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [fileInput, setFileInput] = useState<File | null>(null);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [proposalFiles, setProposalFiles] = useState<
    ProposalFilesType[] | null
  >(null);

  // async function getIntegrations() {
  //   const { data } = await api.get(
  //     '/integration-product/product/name/Saque aniversário FGTS/integrations',
  //   );
  //   console.log(data)

  //   setIntegrations(data);
  // }

  const handleCopyClick = (clientCpf) => () => {
    // Trigger the copy action when a button is clicked
    copy(clientCpf);
  };

  useEffect(() => {
    if (user?.id) {
      if (!user?.partner) {
        // getIntegrations();
        getPartners();
        getLoans();
      } else {
        setPartnerUuid(user.partner.uuid);
      }
    }
  }, [user]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    getLoans();
  }, [page, partnerUuid]);

  const getLoans = async () => {
    setLoading(true);

    const conditionParams: {
      limit: number | string;
      page?: number;
      search: string | null;
      partner?: { uuid: string };
      startDate?: string;
      endDate?: string;
    } = {
      limit: itemsPerPage,
      page: page,
      search: searchValue,
      partner: { uuid: partnerUuid },
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };

    if (searchValue) {
      delete conditionParams.page;
    }

    if (!partnerUuid) {
      delete conditionParams.partner;
    }

    if (startDate && endDate) {
      conditionParams.limit = 'all';
      setPageCount(true);
    }

    const { data } = await api.get(`/proposals`, {
      params: {
        status:
          'progress,pending,analysis,pendingFormalizationSignature,pendingFormalizationSelfie,pendingSubmissionDocuments,pendingResubmissionDocuments,completed,canceled,reproved',
        adminRoute: true,
        sort: {
          'proposal.createdAt': 'DESC',
        },
        ...conditionParams,
      },
    });
    console.log(data.proposals);

    setProposals(data.proposals);
    setExcelData(data.proposals);
    setTotalItems(data.total);
    setLoading(false);
  };

  async function getPartners() {
    const { data } = await api.get('/partners');
    setPartners(data.partners);
  }

  const getExcelData = async () => {
    setLoadingExcelData(true);

    if (!formattedStartDate || !formattedEndDate) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Por gentileza informar as datas para consulta',
      });
      setLoadingExcelData(false);
      return;
    }
    setStatusMessageAlert(null);
    setLoadingExcelData(false);
    getLoans();
    setExportData(true);
  };

  const handleChange: ChangeEventHandler = (event: any) => {
    const inputValue = event.target.value;

    setSearchValue(inputValue);
  };

  const onPreviousClick = (event: any) => {
    const leftPage = document.querySelector('#pagination-left-button');
    const rightPage = document.querySelector('#pagination-right-button');
    if (page > 1) {
      setPage(page - 1);
      leftPage!.classList.remove('block-cursor');
      rightPage!.classList.remove('block-cursor');
    }
    if (page <= 1) {
      event!.preventDefault();
      leftPage!.classList.add('block-cursor');
    }
  };

  const onNextClick = (event: any) => {
    const leftPage = document.querySelector('#pagination-left-button');
    const rightPage = document.querySelector('#pagination-right-button');

    leftPage!.classList.remove('block-cursor');
    if (page < totalPages) setPage(page + 1);

    if (page === totalPages) {
      event!.preventDefault();
      rightPage!.classList.add('block-cursor');
    }
  };

  const handleStartDate = (e: any) => {
    setExportData(false);
    setStartDate(e.target.value);
    const date = e.target.value.split('-');
    const year = date[0];
    const month = date[1];
    const day = date[2];
    const formattedDate = `${day}/${month}/${year}`;
    setFormattedStartDate(formattedDate);
  };

  const handleEndDate = (e: any) => {
    setExportData(false);
    if (e.target.value < startDate) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Data final não pode ser anterior à data inicial',
      });
      return;
    }

    if (startDate <= e.target.value) {
      setStatusMessageAlert(null);
      setEndDate(e.target.value);
      const date = e.target.value.split('-');
      const year = date[0];
      const month = date[1];
      const day = date[2];
      const formattedDate = `${day}/${month}/${year}`;
      setFormattedEndDate(formattedDate);
    }
  };

  const resetFieldsAndStatus = () => {
    setExportData(false);
    setStartDate('');
    setFormattedStartDate('');
    setEndDate('');
    setFormattedEndDate('');
    setStatusMessageAlert(null);
    setPageCount(false);
  };

  const fetchAllProposals = async () => {
    try {
      const conditionParams = {
        limit: "all",
        search: searchValue,
        partner: partnerUuid ? { uuid: partnerUuid } : undefined,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };

      const { data } = await api.get(`/proposals`, {
        params: {
          status:
            "progress,pending,analysis,pendingFormalizationSignature,pendingFormalizationSelfie,pendingSubmissionDocuments,pendingResubmissionDocuments,completed,canceled,reproved",
          adminRoute: true,
          sort: { "proposal.createdAt": "DESC" },
          ...conditionParams,
        },
      });

      return data.proposals; // Retorna todas as propostas
    } catch (error) {
      console.error("Erro ao buscar todas as propostas:", error);
      return [];
    }
  };

  const formatProposalData = (proposal) => {
    return {
      "Nome": proposal.clientName || "-",
      "CPF": proposal.clientCpf || "-",
      "Data - Hora": proposal.createdAt
        ? format(parseISO(proposal.createdAt), "dd/MM/yyyy - HH:mm")
        : "-",
      "Valor": proposal.value
        ? `R$ ${parseFloat(proposal.value).toFixed(2).replace(".", ",")}`
        : "-",
      "Status": statusProposal[proposal.status] || "-",
      "Consultor": proposal.createdByConsultant?.name || "-",
      "Subconta": proposal.partner?.friendlyName || "-",
      ...(user?.role === "ADMIN"
        ? { "Nº Proposta": proposal.contractNumber || "-" }
        : {}),
    };
  };

  const exportToExcel = async () => {
    setLoading(true);

    let allProposals = await fetchAllProposals(); // Busca todas as propostas

    let data = [];

    if (partnerUuid) {
      // Filtra propostas do parceiro selecionado
      data = allProposals
        .filter((p) => p.partner?.uuid === partnerUuid)
        .map((p) => formatProposalData(p));
    } else {
      // Exporta todas as propostas
      data = allProposals.map((p) => formatProposalData(p));
    }

    // Criar a planilha e exportar
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Propostas");

    XLSX.writeFile(workbook, "propostas.xlsx");

    setLoading(false);
  };

  const onChangeSelectPartner = (e: any) => {
    setPartnerUuid(e.target.value);
    resetFieldsAndStatus();
  };

  const handleKeyDownEnter = (e: { keyCode: number }) => {
    if (e.keyCode === 13) {
      resetFieldsAndStatus();
      getLoans();
    }
  };

  const handleClick = () => {
    resetFieldsAndStatus();
    getLoans();
  };

  const openStatusModal = (
    status: React.SetStateAction<string>,
    proposal: Proposal,
  ) => {
    setActualProposal(proposal);
    setActualProposalStatus(status);

    const modal = document.querySelector('#status-modal');
    modal!.classList.remove('hide');
    document.body.classList.add('no-scroll');
    window.scrollTo(0, 0);
  };

  const closeStatusModal = () => {
    const modal = document.querySelector('#status-modal');
    modal!.classList.add('hide');
    document.body.classList.remove('no-scroll');

    if (getLoansInfo === true) {
      getLoans();
      setStatusMessageAlert(null);
    }
  };

  const onChangeProposalStatus = (newStatus: React.SetStateAction<string>) => {
    setnewProposalStatus(newStatus);
  };

  async function updateProposalStatus() {
    const { data } = await api.patch(`/proposals/${actualProposal?.uuid}`, {
      status: newProposalStatus,
    });
    setGetLoansInfo(true);

    if (!data) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Não foi possível atualizar',
      });
      setGetLoansInfo(false);
      return;
    }

    if (data) {
      setStatusMessageAlert({
        status: 'success',
        message: 'Status atualizado',
      });
    }
    return data;
  }

  const checkPendingStatusType = (pendingReason: string) => {
    switch (pendingReason) {
      case 'frontIdentificationDocument': {
        setPendingStatusType('documentFront');
        setDocumentTypeOne('RGFrente');
        break;
      }
      case 'backIdentificationDocument': {
        setPendingStatusType('documentBack');
        setDocumentTypeOne('RGVerso');
        break;
      }

      case 'doubleSidedIdentificationDocument': {
        setPendingStatusType('documentFrontAndBack');
        setDocumentTypeOne('RGFrente');
        setDocumentTypeTwo('RGVerso');
        break;
      }
      case 'otherIdentificationDocumentWithPhoto': {
        setPendingStatusType('otherDocument');
        setDocumentTypeOne('CNHFrente');
        setDocumentTypeTwo('CNHVerso');
        break;
      }
      case 'illegibleIdentificationDocument': {
        setPendingStatusType('documentFrontAndBack');
        setDocumentTypeOne('RGFrente');
        setDocumentTypeTwo('RGVerso');
        break;
      }
      case 'selfie': {
        setPendingStatusType('selfie');
        setDocumentTypeOne('Selfie');
        break;
      }
      case 'proofOfResidence': {
        setPendingStatusType('proofOfResidence');
        setDocumentTypeOne('ComprovanteResidencia');
        break;
      }
      // case 'pendingCustomerContact': {
      //   setPendingStatusType('contactNumber')
      //   break;
      // }
    }
  };

  const openPendingIssueModal = (proposalUuid: string, status: string) => {
    setPendingFileOne(null);
    setPendingFileTwo(null);
    setPendingProposalUuid('');
    const modal = document.querySelector('#pending-issue-modal');
    modal!.classList.remove('hide');
    document.body.classList.add('no-scroll');
    window.scrollTo(0, 0);
    setPendingProposalUuid(proposalUuid);
    checkPendingStatusType(status);
  };

  const closePendingIssueModal = () => {
    setStatusMessageAlert(null);
    setPendingProposalUuid('');
    setPendingFileOne(null);
    setPendingFileTwo(null);
    const modal = document.querySelector('#pending-issue-modal');
    modal!.classList.add('hide');
    document.body.classList.remove('no-scroll');

    if (getLoansInfo === true) {
      getLoans();
      setStatusMessageAlert(null);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const uploadFileOne = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusMessageAlert(null);

    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setPendingFileOne(selectedFile);
    }
  };

  const uploadFileTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusMessageAlert(null);
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setPendingFileTwo(selectedFile);
    }
  };

  const resolvePendingIssue = async () => {
    setStatusMessageAlert({
      status: 'info',
      message: 'Aguarde que estamos resolvendo a pendência.',
    });

    const FormData = require('form-data');
    const form = new FormData();

    if (fileInputRef.current) {
      if (!fileInputRef.current.value) {
        setStatusMessageAlert({
          status: 'error',
          message: 'É necessário anexar o documento para prosseguir',
        });
        return;
      }
    }

    form.append('proposalUuid', pendingProposalUuid);
    form.append('type[]', documentTypeOne);
    form.append('files[]', pendingFileOne);
    if (documentTypeTwo !== '') {
      form.append('type[]', documentTypeTwo);
      form.append('files[]', pendingFileTwo);
    }

    const { data } = await api.post(`fgts/documents/upload`, form);

    if (data.status === 'error') {
      setStatusMessageAlert({
        status: 'error',
        message: 'Não foi possível enviar o arquivo, tente novamente',
      });
      return;
    }

    if (data.status === 'success') {
      const { data } = await api.get(`fgts/reevaluate/${pendingProposalUuid}`);

      if (data.status === 'error') {
        setStatusMessageAlert({
          status: 'error',
          message: 'Não foi possível resolver a pendência, tente novamente',
        });
        return;
      }

      if (data.status === 'success') {
        const proposalIndex = proposals.findIndex(
          (proposal) => proposal.uuid === pendingProposalUuid,
        );

        const updatedProposal = {
          ...proposals[proposalIndex],
          status: 'progress',
        };
        const updatedProposals = [
          ...proposals.slice(0, proposalIndex),
          updatedProposal,
          ...proposals.slice(proposalIndex + 1),
        ];
        setProposals(updatedProposals);
        setStatusMessageAlert(null);
        closePendingIssueModal();
      }
    }
  };

  const openFormalizationLinkModal = (integrationFormalizationLink: string) => {
    setFormalizationLink(integrationFormalizationLink);

    const modal = document.querySelector('#formalization-link-modal');
    modal!.classList.remove('hide');
    document.body.classList.add('no-scroll');
    window.scrollTo(0, 0);
  };

  const openProposalDetailModal = (proposal: Proposal) => {
    setContractNumber(proposal.contractNumber);
    setClientName(proposal.clientName);
    setClientCpf(proposal.clientCpf);

    let formatedClientBirthDate = format(
      parseISO(proposal.clientBirthDate),
      'dd/MM/yyyy',
    );

    setClientBirthDate(formatedClientBirthDate);
    setClientEmail(proposal.clientEmail);
    setClientPhone(proposal.clientPhone);
    setIntegrationFriendlyName(proposal.integration.friendlyName);
    setPartnerFriendlyName(proposal.partner.friendlyName);

    if (
      proposal &&
      proposal.createdByConsultant &&
      proposal.createdByConsultant.name
    ) {
      setCreatedByConsultant(proposal.createdByConsultant.name);
    } else {
      // Lidar com a situação em que alguma propriedade é null ou undefined
      setCreatedByConsultant('Nome não disponível');
    }

    setInstallment(proposal.installment);

    let formatedProposalValue = `R$ ${
      proposal.value
        ? parseFloat(proposal.value)
            .toFixed(2)
            .replace('.', ',')
            .replace(regexMoney, '$&.')
        : ''
    }`;

    setProposalValue(formatedProposalValue);
    setProposalStatusRow(statusProposal[proposal.status]);
    setProtocolId(proposal.integrationsProperties.chatId);

    // Faça o que for necessário com as partes recuperadas
    //console.log('Número do Contrato:', contractNumber);

    const modal = document.querySelector('#proposal-details-modal');
    modal!.classList.remove('hide');
    document.body.classList.add('no-scroll');
    window.scrollTo(0, 0);
  };

  const closeProposalDetailModal = () => {
    const modal = document.querySelector('#proposal-details-modal');
    modal!.classList.add('hide');
    document.body.classList.remove('no-scroll');

    if (getLoansInfo === true) {
      getLoans();
      setStatusMessageAlert(null);
    }
  };

  const closeFormalizationLinkModal = () => {
    const modal = document.querySelector('#formalization-link-modal');
    modal!.classList.add('hide');
    document.body.classList.remove('no-scroll');

    if (getLoansInfo === true) {
      getLoans();
      setStatusMessageAlert(null);
    }
  };

  function formatPhoneNumber(phoneNumber) {
    // Remover caracteres não numéricos
    const numericOnly = phoneNumber.replace(/\D/g, '');

    // Formatar o número
    const formattedNumber = `(${numericOnly.substring(
      0,
      2,
    )}) ${numericOnly.substring(2, 3)} ${numericOnly.substring(
      3,
      7,
    )}-${numericOnly.substring(7)}`;

    return formattedNumber;
  }

  async function requestCancelProposal() {
    try {
      setLoadingModal(true);

      const response = await api.post(
        `/fgts/pay/request-cancellation/${actualProposal?.uuid}`,
        { reason: reasonCancellation },
      );

      if (response.data.status === 'error') {
        setStatusMessageAlert({
          status: response.data.status,
          message: response.data.message,
        });

        return;
      }

      setStatusMessageAlert({
        status: 'info',
        message: response.data.message,
      });

      setTimeout(() => {
        setShowModal(false);
      }, 5000);
    } catch (error) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Não foi possível cancelar a proposta, tente novamente.',
      });
    } finally {
      setLoadingModal(false);
    }
  }

  async function getProposalFileUpload(proposalUuid: string) {
    try {
      setProposalFiles(null);
      setLoadingModal(true);
      const response = await api.get(`/proposals/${proposalUuid}/file-upload`);

      setProposalFiles(response.data.files);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
    }
  }

  function openModal(proposal: Proposal, modalType: ModalType) {
    setStatusMessageAlert(null);
    setModalType(modalType);
    setActualProposal(proposal);
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  function SelectModal(properties: { modal: ModalType | null }) {
    const { modal } = properties;

    if (modal === ModalType.CANCEL) return <ModalCancel />;

    if (modal === ModalType.ADD_FILE) return <ModalAddFile />;

    if (modal === ModalType.INDEX_FILE) return <ModalIndexFile />;

    return <></>;
  }

  function ModalCancel() {
    return (
      <>
        <div className="modal-change-container">
          <div className="form-group col-md-12">
            <h2>Cancelamento de proposta</h2>
          </div>
          <div className="form-group col-md-6">
            <label>Digite o motivo do cancelamento *</label>
            <input
              className={`form-control`}
              onChange={(e: any) => setReasonCancellation(e.target.value)}
              value={reasonCancellation}
            />
          </div>
        </div>
        {getLoansInfo ? (
          ''
        ) : (
          <div className="update-status-container">
            <button
              className="update-status-button"
              onClick={requestCancelProposal}
              disabled={loadingModal}
            >
              {loadingModal ? (
                <ImSpinner11 className="spinner" size={16} />
              ) : (
                'Cancelar'
              )}
            </button>
          </div>
        )}
      </>
    );
  }

  function ModalAddFile() {
    return (
      <>
        <div className="modal-change-container">
          <div className="form-group col-md-12">
            <h2>Adicionar nota fiscal a proposta</h2>
          </div>
          <div className="form-group col-md-12">
            <input
              type="file"
              id="proposal-file-upload"
              accept="image/*,application/pdf"
              onChange={(e) => setFileInput(e.target?.files?.[0] || null)}
              style={{ display: 'none' }}
            />
            <label
              className="file-input-label"
              htmlFor={loadingModal ? '' : 'proposal-file-upload'}
            >
              Selecionar arquivo
            </label>
            <label>{fileInput?.name}</label>
          </div>
        </div>
        {getLoansInfo ? (
          ''
        ) : (
          <div className="update-status-container">
            <button
              className="update-status-button"
              onClick={proposalFileUpload}
              disabled={loadingModal}
            >
              {loadingModal ? (
                <ImSpinner11 className="spinner" size={16} />
              ) : (
                'Enviar'
              )}
            </button>
          </div>
        )}
      </>
    );
  }

  function ModalIndexFile() {
    return (
      <>
        <div className="modal-change-container">
          <div className="form-group col-md-12">
            <h2>Notas fiscais da proposta {actualProposal?.contractNumber}</h2>
          </div>
          <div className="form-group col-md-12">
            <div className="table-responsive">
              <table className="table table-light table-sm table-hover">
                <thead>
                  <tr>
                    <th>
                      Nota fiscal
                      {proposalFiles ? ` (${proposalFiles.length})` : ''}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loadingModal ? (
                    <tr>
                      <td style={{ textAlign: 'center' }}>
                        <strong>Carregando...</strong>
                      </td>
                    </tr>
                  ) : proposalFiles && proposalFiles?.length < 1 ? (
                    <tr>
                      <td style={{ textAlign: 'center' }}>
                        <strong>Não há notas fiscais para exibir</strong>
                      </td>
                    </tr>
                  ) : (
                    proposalFiles?.map((proposalFile) => (
                      <tr>
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/uploads/${proposalFile.name}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {proposalFile.originalName}
                          </a>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {getLoansInfo ? (
          ''
        ) : (
          <div className="update-status-container">
            <button
              className="update-status-button"
              onClick={() => setModalType(ModalType.ADD_FILE)}
            >
              Adicionar
            </button>
          </div>
        )}
      </>
    );
  }

  async function proposalFileUpload() {
    try {
      setStatusMessageAlert(null);
      setLoadingModal(true);

      const formData = new FormData();

      formData.append('uploadType', 'proposals');
      formData.append('file', fileInput);

      const response = await api.post(
        `/proposals/${actualProposal?.uuid}/file-upload`,
        formData,
      );

      setStatusMessageAlert({
        status: 'info',
        message: response.data.message,
      });
      setFileInput(null);

      setTimeout(() => {
        setShowModal(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      setStatusMessageAlert({
        status: 'error',
        message:
          'Algo ocorreu ao tentar enviar o arquivo, tente novamente mais tarde.',
      });
    } finally {
      setLoadingModal(false);
    }
  }

  return (
    <Layout
      pageBreadcrumb="Propostas realizadas"
      pageTitle="Propostas realizadas"
    >
      <div className="container">
        <div className="proposals-form-container">
          <input
            onChange={handleChange}
            type="text"
            placeholder="Digite o Nome ou CPF"
            onKeyDown={handleKeyDownEnter}
          ></input>
          <button type="button" onClick={handleClick}>
            <img src={lupaBusca} alt="lupa de busca" title="lupa de busca" />
          </button>
        </div>
        {user?.id && !user?.partner ? (
          <div className="proposal-bank-partner-choice">
            <label>Escolha o parceiro</label>
            <select value={partnerUuid} onChange={onChangeSelectPartner}>
              <option value="">Sem parceiro</option>
              {partners.map((option) => (
                <option key={option.uuid} value={option.uuid}>
                  {option.friendlyName}
                </option>
              ))}
            </select>
          </div>
        ) : (
          ''
        )}
        <div className="excel-container">
          <div className="date-container">
            <div className="dates-info">
              <label>Data inicial:</label>
              <input
                type="date"
                min="1997-01-01"
                max="2030-12-31"
                value={startDate}
                onChange={(e: any) => handleStartDate(e)}
              />
            </div>
            <div className="dates-info">
              <label>Data final:</label>
              <input
                type="date"
                min="1997-01-01"
                max="2030-12-31"
                value={endDate}
                onChange={(e: any) => handleEndDate(e)}
              />
            </div>
          </div>
          <div>
            <button onClick={getExcelData}>Prosseguir</button>
            {statusMessageAlert && (
              <div
                className={
                  statusMessageAlert.status === 'error'
                    ? 'alert alert-danger alert-text-center'
                    : 'alert alert-primary alert-text-center'
                }
                style={{ marginTop: '10px' }}
                role="alert"
              >
                {statusMessageAlert.message}
              </div>
            )}
          </div>
          {exportData ? (
            <div className="excel-button-container">
              <ExportToExcel apiData={proposals} fileName={fileName} />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="my-loans" style={{ overflow: 'auto' }}>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-light table-sm table-hover">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>CPF</th>
                      <th>Data - Hora</th>
                      <th>Valor</th>
                      <th>Status</th>
                      <th>Consultor</th>
                      <th>Subconta</th>
                      {user?.role === 'ADMIN' && <th>Nº Proposta</th>}
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td
                          colSpan={user?.role === 'ADMIN' ? 9 : 8}
                          style={{ textAlign: 'center' }}
                        >
                          <strong>Carregando informações</strong>
                        </td>
                      </tr>
                    ) : proposals?.length < 1 ? (
                      <tr>
                        <td
                          colSpan={user?.role === 'ADMIN' ? 9 : 8}
                          style={{ textAlign: 'center' }}
                        >
                          <strong>Não há propostas para exibir</strong>
                        </td>
                      </tr>
                    ) : (
                      proposals?.map((proposal) => {
                        return (
                          <tr key={proposal.uuid}>
                            <td
                              title={`id: ${proposal.uuid} nome: ${proposal.clientName}`}
                            >
                              {proposal.clientName ? proposal.clientName : ''}
                            </td>
                            <td onClick={handleCopyClick(proposal.clientCpf)}>
                              {proposal.clientCpf ? proposal.clientCpf : ''}
                            </td>
                            <td>
                              {proposal.createdAt
                                ? format(
                                    parseISO(proposal.createdAt),
                                    'dd/MM/yyyy - HH:mm',
                                  )
                                : ''}
                            </td>
                            <td>
                              R${' '}
                              {proposal.value
                                ? parseFloat(proposal.value)
                                    .toFixed(2)
                                    .replace('.', ',')
                                    .replace(regexMoney, '$&.')
                                : ''}
                            </td>
                            <td>
                              {loadingTr === proposal.uuid
                                ? '...'
                                : statusProposal[proposal.status]}
                            </td>
                            <td>
                              {proposal.createdByConsultant
                                ? proposal.createdByConsultant.name
                                : ''}
                            </td>
                            <td>
                              {proposal.partner
                                ? proposal.partner.friendlyName
                                : ''}
                            </td>
                            {user?.role === 'ADMIN' && (
                              <td
                                title={`Banco: ${proposal?.integration?.friendlyName ?? ''}`}
                              >
                                {proposal?.integration?.name === 'zipdin' ? (
                                  <a
                                    href={`https://zipcred.zipdin.com.br/saque-fgts/cadastro-credito-pessoal/${proposal.contractNumber}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {proposal.contractNumber}
                                  </a>
                                ) : proposal.integration?.name === 'mercantil' ||
                                  proposal.integration?.name === 'creditaqui' ? (
                                  <a
                                    href={`https://meumb.mercantil.com.br/propostas/${proposal.contractNumber}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {proposal.contractNumber}
                                  </a>
                                ) : (
                                  <span>{proposal.contractNumber}</span>
                                )}
                              </td>
                            )}
                            <td>
                              {(proposal.status === 'pending' ||
                                proposal.status === 'progress' ||
                                proposal.status === 'completed') &&
                              proposal.fgtsPay === true ? (
                                <MdAttachFile
                                  className="edit-icon"
                                  title="Notas fiscais"
                                  onClick={() => {
                                    getProposalFileUpload(proposal.uuid);
                                    openModal(proposal, ModalType.INDEX_FILE);
                                  }}
                                  style={{ paddingRight: 2 }}
                                />
                              ) : (
                                ''
                              )}
                              {proposal.status !== 'canceled' &&
                              proposal.status !== 'reproved' &&
                              proposal.fgtsPay === true ? (
                                <MdOutlineCancel
                                  className="edit-icon"
                                  title="Cancelar proposta"
                                  onClick={() =>
                                    openModal(proposal, ModalType.CANCEL)
                                  }
                                  style={{ paddingRight: 2 }}
                                />
                              ) : (
                                ''
                              )}
                              {user?.role === 'ADMIN' &&
                                proposal.fgtsPay === false && (
                                  <FaEye
                                    className="details-link-icon"
                                    color="#000"
                                    title="Detalhes da proposta"
                                    onClick={() =>
                                      openProposalDetailModal(proposal)
                                    }
                                  />
                                )}
                              {proposal.fgtsPay === false ? (
                                <Link
                                  to={`/admin/fgts/${proposal.uuid}/digital-authorization`}
                                >
                                  <FaLink
                                    color="#000"
                                    title="Link da autorização digital"
                                  />
                                </Link>
                              ) : (
                                <></>
                              )}

                              {proposal.status === 'analysis' &&
                              proposal.fgtsPay === false ? (
                                <FaPencilAlt
                                  className="edit-icon"
                                  onClick={() =>
                                    openStatusModal(
                                      statusProposal[proposal.status],
                                      proposal,
                                    )
                                  }
                                />
                              ) : (
                                ''
                              )}
                              {proposal.status === 'pending' &&
                              proposal.fgtsPay === false ? (
                                <FaPenSquare
                                  className="resolve-issue-icon"
                                  onClick={() =>
                                    openPendingIssueModal(
                                      proposal.uuid,
                                      proposal.pendingReason,
                                    )
                                  }
                                ></FaPenSquare>
                              ) : (
                                ''
                              )}
                              {proposal.integrationFormalizationLink &&
                              proposal.fgtsPay === false ? (
                                <FaLink
                                  className="formalization-link-icon"
                                  color="#000"
                                  title="Link de formalização da proposta"
                                  onClick={() =>
                                    openFormalizationLinkModal(
                                      proposal.integrationFormalizationLink,
                                    )
                                  }
                                />
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="pagination-container">
          <button id="pagination-left-button" onClick={onPreviousClick}>
            Anterior
          </button>
          <div className="page-number-container">
            {pageCount ? '01 de 01' : `${page} de ${totalPages}`}
          </div>
          <button id="pagination-right-button" onClick={onNextClick}>
            Próximo
          </button>
        </div>
      </div>

      <div className={`modal-default ${showModal ? '' : 'hide'}`}>
        <div className="modal-container">
          <div className="close-button-container">
            <button onClick={closeModal}>
              <p>X</p>
            </button>
          </div>
          <SelectModal modal={modalType} />
          <div className="error-container">
            {statusMessageAlert && (
              <div
                className={
                  statusMessageAlert.status === 'error'
                    ? 'alert alert-danger'
                    : 'alert alert-primary'
                }
                role="alert"
              >
                {statusMessageAlert.message}
              </div>
            )}
          </div>
        </div>
      </div>

      <div id="status-modal" className="hide">
        <div className="status-modal-container">
          <div className="close-button-container">
            <button onClick={closeStatusModal}>
              <p>X</p>
            </button>
          </div>
          <h2>Escolha o novo status a ser aplicado a esta proposta:</h2>

          <div className="status-change-container">
            <select
              className="form-control"
              value={newProposalStatus}
              onChange={(e: any) => onChangeProposalStatus(e.target.value)}
            >
              {proposalStatus.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          {getLoansInfo ? (
            ''
          ) : (
            <div className="update-status-container">
              <button
                className="update-status-button"
                onClick={updateProposalStatus}
              >
                Atualizar status
              </button>
            </div>
          )}

          <div className="error-container">
            {statusMessageAlert && (
              <div
                className={
                  statusMessageAlert.status === 'error'
                    ? 'alert alert-danger'
                    : 'alert alert-primary'
                }
                role="alert"
              >
                {statusMessageAlert.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div id="pending-issue-modal" className="hide">
        <div className="pending-issue-modal-container">
          <div className="close-button-container">
            <button onClick={closePendingIssueModal}>
              <p>X</p>
            </button>
          </div>
          {pendingStatusType === 'documentFrontAndBack' && (
            <div className="pending-issue-change-container">
              <h2>Anexe o documento de identificação</h2>
              <label>Frente do documento</label>
              <input
                type="file"
                name="file"
                accept="image/*"
                onChange={(e) => uploadFileOne(e)}
                ref={fileInputRef}
              />
              <label>Verso do documento</label>
              <input
                type="file"
                name="file"
                accept="image/*"
                onChange={(e) => uploadFileTwo(e)}
                ref={fileInputRef}
              />
            </div>
          )}
          {pendingStatusType === 'documentFront' && (
            <div className="pending-issue-change-container">
              <h2>Anexe a imagem da frente do documento</h2>
              <input
                type="file"
                name="file"
                accept="image/*"
                onChange={(e) => uploadFileOne(e)}
                ref={fileInputRef}
              />
            </div>
          )}
          {pendingStatusType === 'documentBack' && (
            <div className="pending-issue-change-container">
              <h2>Anexe a imagem do verso do documento</h2>
              <input
                type="file"
                name="file"
                accept="image/*"
                onChange={(e) => uploadFileOne(e)}
                ref={fileInputRef}
              />
            </div>
          )}
          {pendingStatusType === 'otherDocument' && (
            <div className="pending-issue-change-container">
              <h2>Anexe a foto da CNH</h2>
              <label>Frente do documento</label>
              <input
                type="file"
                name="file"
                accept="image/*"
                onChange={(e) => uploadFileOne(e)}
                ref={fileInputRef}
              />
              <label>Verso do documento</label>
              <input
                type="file"
                name="file"
                accept="image/*"
                onChange={(e) => uploadFileTwo(e)}
                ref={fileInputRef}
              />
            </div>
          )}
          {pendingStatusType === 'selfie' && (
            <div className="pending-issue-change-container">
              <h2>Anexe a sua selfie</h2>
              <input
                type="file"
                name="file"
                accept="image/*"
                onChange={(e) => uploadFileOne(e)}
                ref={fileInputRef}
              />
            </div>
          )}
          {pendingStatusType === 'proofOfResidence' && (
            <div className="pending-issue-change-container">
              <h2>Anexe um comprovante de residência</h2>
              <label>* Comprovante dos últimos 3 meses</label>
              <input
                type="file"
                name="file"
                accept="image/*,application/pdf,.doc,.docx"
                onChange={(e) => uploadFileOne(e)}
                ref={fileInputRef}
              />
            </div>
          )}
          <div className="update-status-container">
            <button
              className="update-status-button"
              onClick={resolvePendingIssue}
            >
              Resolver pendência
            </button>
          </div>
          <div className="error-container">
            {statusMessageAlert && (
              <div
                className={
                  statusMessageAlert.status === 'error'
                    ? 'alert alert-danger'
                    : 'alert alert-primary'
                }
                role="alert"
              >
                {statusMessageAlert.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div id="formalization-link-modal" className="hide">
        <div className="formalization-link-modal-container">
          <div className="close-button-container">
            <button onClick={closeFormalizationLinkModal}>
              <p>X</p>
            </button>
          </div>
          <div className="formalization-link-info-container">
            <h2>Segue abaixo o link de formalização</h2>
            <input type="text" value={formalizationLink}></input>
          </div>
        </div>
      </div>
      <div id="proposal-details-modal" className="hide">
        <div className="proposal-details-modal-container">
          <div className="iclose-button-container">
            <button onClick={closeProposalDetailModal}>
              <p>X</p>
            </button>
          </div>
          <div className="proposal-details-modal-info-container">
            <h2>PROPOSTA {contractNumber} </h2>
            <h3>Dados do cliente</h3>

            <div className="row">
              <div className="col-md-4 w-100">
                <p className="phead">Nome:</p>
                <p className="pdata">{clientName}</p>
              </div>
              <div className="col-md-4 w-100">
                <p className="phead">CPF:</p>
                <p className="pdata">{clientCpf}</p>
              </div>
              <div className="col-md-4 w-100">
                <p className="phead">Data de nascimento:</p>
                <p className="pdata">{clientBirthDate}</p>
              </div>
              <div className="col-md-4 w-100">
                <p className="phead">E-mail:</p>
                <p className="pdata">{clientEmail}</p>
              </div>
              <div className="col-md-4 w-100">
                <p className="phead">Celular:</p>
                <p className="pdata">{formatPhoneNumber(clientPhone)}</p>{' '}
              </div>
              <div className="col-md-4 w-100">
                <p className="phead">Protocolo:</p>
                <p className="pdata">{protocolId}</p>
              </div>
            </div>
            <h3>Dados da proposta</h3>
            <div className="row">
              <div className="col-md-4 w-100">
                <p className="phead">Banco:</p>
                <p className="pdata">{integrationFriendlyName}</p>
              </div>
              <div className="col-md-4 w-100">
                <p className="phead">Consultor:</p>
                <p className="pdata">{createdByConsultant}</p>
              </div>
              <div className="col-md-4 w-100">
                <p className="phead">Valor:</p>
                <p className="pdata">{proposalValue}</p>
              </div>
              <div className="col-md-4 w-100">
                <p className="phead">Parcelas:</p>
                <p className="pdata">{installment}</p>
              </div>
              <div className="col-md-4 w-100">
                <p className="phead">Status:</p>
                <p className="pdata">{proposalStatusRow}</p>{' '}
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div className="container">
        <div className="export-session">
          <button className="export-button" onClick={exportToExcel}>
            <i className="export-icon"><GoFileSymlinkFile /></i>
            Exportar Dados
          </button>
        </div>
      </div>
    </Layout>
  );
}
