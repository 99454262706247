import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './styles.scss'
import '../../styles/global.scss';

import { useAuth } from '../../hooks/useAuth';

import { Button } from '../../components/Button';

import logoImg from '../../assets/logo/logo_header.svg';

type StatusAlertType = {
  status: string;
  message: string | HTMLElement;
};

export default function ResetPassword() {
  const [statusMessageAlert, setStatusMessageAlert] = useState<StatusAlertType | null>(null);
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<string | null>(null);

  const { token } = useParams<{ token?: string }>();
  const { loading, handleResetPassword } = useAuth();

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === "true") {}
  }, []);

  function validatePassword(password: string): string | null {
    if (password.length < 6) {
      return 'A senha deve ter no mínimo 6 caracteres.';
    }
    if (password.length > 32) {
      return 'A senha deve ter no máximo 32 caracteres.';
    }
    if (!/(?=.*[a-z])/.test(password)) {
      return 'A senha deve conter pelo menos uma letra minúscula.';
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      return 'A senha deve conter pelo menos uma letra maiúscula.';
    }
    if (!/(?=.*\d)|(?=.*\W+)/.test(password)) {
      return 'A senha deve conter pelo menos um número ou um símbolo.';
    }
    return null;
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordError(validatePassword(newPassword));
  };

  const [showPassword, setShowPassword] = useState(false);

  async function handleChangePassword() {
    setStatusMessageAlert(null);

    if (!password || !passwordConfirmation) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha todos os campos para continuar.',
      });
      return;
    }

    const passwordValidationMessage = validatePassword(password);
    if (passwordValidationMessage) {
      setPasswordError(passwordValidationMessage);
      return;
    }

    if (password !== passwordConfirmation) {
      setPasswordConfirmationError('Senhas não conferem, favor verificar.');
      return;
    }

    setPasswordConfirmationError(null);

    try {
      const response = await handleResetPassword(token, password, passwordConfirmation);

      if (response) {
        setStatusMessageAlert({
          status: 'info',
          message: response.message as string,
        });
      }
    } catch (error) {
      const { data } = error.response;
      setStatusMessageAlert({
        status: "error",
        message:
          data.message === "Internal server error"
            ? "Ocorreu um erro inesperado no servidor. Tente novamente mais tarde."
            : Array.isArray(data.message)
            ? data.message.join(". ")
            : data.message,
      });
    }
  }

  return (
    <div id="page-auth">
      <aside>
        <strong>Alteração de senha</strong>
        <p>Aqui você altera sua senha.</p>
      </aside>
      <main>
        <div className="main-content">
          <div className="logoTnb"><img src={logoImg} alt="Ta no bolso" /></div>
          <form onSubmit={(e) => e.preventDefault()}>
            <h2>Alterar sua senha</h2>

            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Senha*</label>
                <input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                  onChange={handlePasswordChange}
                  value={password}
                />
                {passwordError && <div className="invalid-feedback">{passwordError}</div>}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Confirmação de senha*</label>
                <input
                  name="passwordConfirmation"
                  type={showPassword ? "text" : "password"}
                  className={`form-control ${passwordConfirmationError ? 'is-invalid' : ''}`}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  value={passwordConfirmation}
                />
                {passwordConfirmationError && <div className="invalid-feedback">{passwordConfirmationError}</div>}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <div className="form-check-controll mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="showPassword"
                    onChange={() => setShowPassword(!showPassword)}
                    checked={showPassword}
                  />
                  <label className="form-check-label" htmlFor="showPassword">
                    Mostrar senha
                  </label>
                </div>
              </div>
            </div>
            {statusMessageAlert && (
              <div
                className={
                  statusMessageAlert.status === 'error' ? 'alert alert-danger alert-text-center' : 'alert alert-primary alert-text-center'
                }
                role="alert"
              >
                {statusMessageAlert.message}
              </div>
            )}

            <Button type="button" onClick={handleChangePassword}>
              {!loading ? 'Salvar' : 'Salvando...'}
            </Button>
            <p><Link to="/signin">Voltar ao login.</Link></p>
          </form>
        </div>
      </main>
    </div>
  );
}
