import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import * as XLSX from "xlsx";
import InputMask from 'react-input-mask';
import { useHistory, useLocation } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';

import { isValidCpf } from '../../../../utils/helpers';
import Layout from '../../../../components/Admin/Layout';
import { api } from '../../../../services/api';
import './styles.scss';
import { useAuth } from '../../../../hooks/useAuth';
import {
  formatDateBrToSql,
  formatStringNumberCommaToDot,
} from '../../../../utils/format';

export type LocationStateType = {
  name?: string;
  cpf?: string;
  cellPhoneNumber: string;
  email: string;
  parcelas?: [];
  totalValueInstallments?: number;
  integrationName: string;
  message?: string;
  status?: string;
  url?: string;
};

export type PartnersType = {
  uuid: string;
  name: string;
  friendlyName: string;
  cnpj: string;
  corporateName: string;
  openingDate: string;
  email: string;
  phone: string;
  logo: string;
  backgroundColorLogo: string;
  ownWhatsAppNumber: string;
  active: boolean;
};

type StatusAlertType = {
  status: string;
  message: ReactElement | string;
};

enum TypeProposal {
  NORMAL = 'normal',
  PLATFORM = 'platform',
}

const Create = () => {
  let history = useHistory();
  const today = new Date();
  const todayFormatted = `${today.getDate()}/${(
    '0' +
    (today.getMonth() + 1)
  ).slice(-2)}/${today.getFullYear()}`;
  const { user } = useAuth();
  const [typeProposal, setTypeProposal] = useState<TypeProposal | null>(null);
  const [statusMessageAlert, setStatusMessageAlert] =
    useState<StatusAlertType | null>(null);
  const [name, setName] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');
  const [createdAt, setCreatedAt] = useState<string>(todayFormatted);
  const [cellPhoneNumber, setCellPhoneNumber] = useState<string>('');
  const [integrationUuid, setIntegrationUuid] = useState<string>('');
  const [integrations, setIntegrations] = useState<any>([]);
  const [partnerUuid, setPartnerUuid] = useState<string>('');
  const [partners, setPartners] = useState<PartnersType[]>([]);
  const [contractNumber, setContractNumber] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [installment, setInstallment] = useState<string>('');
  const [huggyChatId, setHuggyChatId] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const search = useLocation().search;

  async function getIntegrations() {
    const { data } = await api.get(
      '/integration-product/product/name/Saque aniversário FGTS/integrations',
    );

    setIntegrations(data);
  }

  async function getPartners() {
    const { data } = await api.get('/partners', {
      params: {
        tnbConsultantCanCreateProposal: true,
      },
    });

    setPartners(data.partners);
  }

  useEffect(() => {
    if (process.env.REACT_APP_FAKER === 'true') {
      setName('João das Couves');
      setEmail('contato@tanobolso.com.br');
      setCpf('15710912034');
      setCellPhoneNumber('48998263717');
      setBirthDate('27/01/1991');
    }

    if (user?.id) {
      getIntegrations();

      if (!user?.partner) {
        getPartners();
      } else {
        setPartnerUuid(user.partner.uuid);
      }
    }
  }, [user]);

  async function handleSimulate(event: FormEvent) {
    event.preventDefault();

    setStatusMessageAlert(null);

    if (
      name === '' ||
      cpf === '' ||
      email === '' ||
      birthDate === '' ||
      cellPhoneNumber === '' ||
      (typeProposal === TypeProposal.PLATFORM &&
        (value === '' ||
          installment === '' ||
          contractNumber === '' ||
          createdAt === ''))
    ) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Preencha todos os campos obrigatórios.',
      });
      return;
    }

    const splitName = name.split(' ');

    if (splitName.length < 2) {
      setStatusMessageAlert({
        status: 'error',
        message: 'O nome precisa ser completo, favor corrigir.',
      });
      return;
    }

    if (user?.id && !user?.partner && integrationUuid === '') {
      setStatusMessageAlert({
        status: 'error',
        message:
          'A escolha do banco é obrigatória e a escolha do parceiro é opcional.',
      });
      return;
    }

    if (!isValidCpf(cpf)) {
      setStatusMessageAlert({
        status: 'error',
        message: 'Número de CPF inválido.',
      });
      return;
    }

    setStatusMessageAlert({
      status: 'info',
      message:
        typeProposal === TypeProposal.NORMAL
          ? 'Só um momento, estamos consultando as parcelas...'
          : 'Salvando...',
    });

    const formattedEmail = email.trim();
    const formattedcellPhone = cellPhoneNumber.replace(/[^0-9]/g, '');
    const formattedValue = formatStringNumberCommaToDot(value);

    try {
      let url;
      let body;

      if (typeProposal === TypeProposal.NORMAL) {
        url = '/fgts/balance';
        body = {
          cpf: cpf.replace(/[^0-9]/g, ''),
          name,
          birthDate,
          email: formattedEmail,
          cellPhone: formattedcellPhone,
          integrationUuid,
          partnerUuid,
          integrationsProperties: {
            huggy: {
              chatId: huggyChatId,
            },
          },
          value: formattedValue,
          installment,
        };
      } else {
        const integration = integrations.filter(
          (integration) => integration.integration.uuid === integrationUuid,
        );
        url = '/proposals';
        body = {
          rate: 0,
          clientCpf: cpf.replace(/[^0-9]/g, ''),
          clientName: name,
          clientBirthDate: birthDate,
          clientEmail: formattedEmail,
          clientPhone: formattedcellPhone,
          status: 'completed',
          comments,
          integration: {
            uuid: integrationUuid,
          },
          partner: partnerUuid
            ? {
                uuid: partnerUuid,
              }
            : null,
          product: integration[0].product,
          integrationsProperties: {
            huggy: {
              chatId: huggyChatId,
            },
          },
          value: formattedValue,
          installment,
          contractNumber,
          createdAt: `${formatDateBrToSql(createdAt)}T12:00:00.000Z`,
        };
      }

      const { data } = await api.post(url, body);

      if (typeProposal === TypeProposal.PLATFORM && data?.uuid) {
        setTypeProposal(null);
        resetForm();
        setStatusMessageAlert({
          status: 'success',
          message: 'Proposta cadastrada com sucesso.',
        });
        setTimeout(
          () => {
            setStatusMessageAlert(null);
          },
          3000,
          event,
        );
        return;
      }

      if (data?.balance === 0) {
        setStatusMessageAlert({
          status: 'error',
          message:
            'Infelizmente não existe saldo suficiente para prosseguir com a operação.',
        });
      }

      if (data.status === 'success') {
        setStatusMessageAlert(null);

        const { proposalUuid } = data;

        if (proposalUuid) {
          await handleHire(proposalUuid, data.integration.friendlyName);
        }
      } else if (data.status === 'error') {
        setStatusMessageAlert({
          status: 'error',
          message: data?.error?.message || data.message,
        });
      } else {
        setStatusMessageAlert({
          status: 'error',
          message: 'Ops, algo ocorreu, tente novamente.',
        });
      }
    } catch (error) {
      console.log(error);
      setStatusMessageAlert({
        status: 'error',
        message: 'Ops, algo ocorreu, tente novamente.',
      });
    }
  }

  const handleHire = async (proposalUuid: string, integrationName: string) => {
    history.push({
      pathname: `/admin/fgts/${proposalUuid}/balance`,
      state: { cpf, name, integrationName, email, cellPhoneNumber },
    });
  };

  useEffect(() => {
    const newSearch = new URLSearchParams(search);

    const clientName = newSearch.get('nome-cliente');
    if (clientName) {
      setName(clientName);
    }

    const clientCpf = newSearch.get('cpf-cliente');
    if (clientCpf) {
      setCpf(clientCpf);
    }

    const clientBirthDate = newSearch.get('data-nascimento');
    if (clientBirthDate) {
      setBirthDate(clientBirthDate);
    }

    const clientEmail = newSearch.get('email');
    if (clientEmail) {
      setEmail(clientEmail);
    }

    const clientPhone = newSearch.get('celular');
    const formattedClientPhone = clientPhone?.slice(2, 13);

    if (formattedClientPhone?.length === 10) {
      const phoneArray = Array.from(formattedClientPhone);
      phoneArray.splice(2, 0, '9');
      setCellPhoneNumber(phoneArray.join(''));
    }

    if (formattedClientPhone?.length === 11) {
      setCellPhoneNumber(formattedClientPhone);
    }

    const integrationName = newSearch.get('banco');
    if (integrationName) {
      console.log(integrationName);
    }

    const huggyChatId = newSearch.get('protocolo');
    if (huggyChatId) {
      console.log(huggyChatId);
      setHuggyChatId(huggyChatId);
    }

    // aqui
    const contractNumber = newSearch.get('numero-proposta');
    if (contractNumber) {
      console.log(contractNumber);
      setContractNumber(contractNumber);
    }

    const proposalValue = newSearch.get('valor');
    if (proposalValue) {
      console.log(proposalValue);
      setValue(proposalValue);
    }

    const installment = newSearch.get('parcelas');
    if (installment) {
      console.log(installment);
      setInstallment(installment);
    }
  }, []);

  const onChangeSelectIntegration = (e: any) => {
    setIntegrationUuid(e.target.value);
  };

  const onChangeSelectPartner = (e: any) => {
    setPartnerUuid(e.target.value);
  };

  const handleTypeProposal = (type: TypeProposal) => {
    setTypeProposal(type);
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setCpf('');
    setCellPhoneNumber('');
    setBirthDate('');
    setComments('');
    setHuggyChatId('');
    setInstallment('');
    setContractNumber('');
    setValue('');
    setIntegrationUuid('');

    if (user?.id && !user?.partner) setPartnerUuid('');
  };

  return (
    <Layout pageBreadcrumb="Criar proposta" pageTitle="Criar proposta">
      <h3>Que tipo de proposta você vai digitar?</h3>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, padding: '1em' }}>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => handleTypeProposal(TypeProposal.NORMAL)}
          >
            Normal
          </button>
        </div>
        <div style={{ flex: 1, padding: '1em' }}>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => handleTypeProposal(TypeProposal.PLATFORM)}
          >
            Plataforma
          </button>
        </div>
      </div>
      <div
        className="estimate"
        style={{ display: `${typeProposal ? 'block' : 'none'}` }}
      >
        <h3 style={{ paddingBottom: '20px' }}>
          {typeProposal === TypeProposal.NORMAL
            ? 'Preencha os dados abaixo para simular'
            : 'Preencha os dados'}
          :
        </h3>
        <form onSubmit={handleSimulate} style={{ paddingBottom: '0' }}>
          <div className="row">
            <div className="form-group col-md-6">
              <label>Nome completo *</label>
              <input
                className={`form-control`}
                onChange={(e: any) => setName(e.target.value)}
                value={name}
              />
            </div>
            <div className="form-group col-md-6">
              <label>CPF *</label>
              <InputMask
                mask="999.999.999-99"
                className={`form-control`}
                autoComplete="off"
                placeholder=""
                value={cpf}
                onChange={(e: any) => setCpf(e.target.value)}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Data de nascimento *</label>
              <InputMask
                mask="99/99/9999"
                className={`form-control`}
                autoComplete="off"
                placeholder=""
                value={birthDate}
                onChange={(e: any) => setBirthDate(e.target.value)}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Celular (que possua WhatsApp) *</label>
              <InputMask
                mask="(99) 99999-9999"
                className={`form-control`}
                autoComplete="off"
                placeholder=""
                value={cellPhoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCellPhoneNumber(e.target.value)
                }
              />
            </div>
            <div className="form-group col-md-6">
              <label>E-mail *</label>
              <input
                className={`form-control `}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                value={email}
              />
            </div>
            {user?.id && !user?.partner ? (
              <div className="form-group col-md-6 bank-info-auth-container">
                <div className="bank-partner-choice">
                  <label>Escolha o banco *</label>
                  <select
                    value={integrationUuid}
                    onChange={onChangeSelectIntegration}
                  >
                    <option value="">Selecione...</option>
                    {integrations.map((option: any) => (
                      <option
                        key={option.integration.uuid}
                        value={option.integration.uuid}
                      >
                        {option.integration.friendlyName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="bank-partner-choice">
                  <label>Escolha o parceiro</label>
                  <select value={partnerUuid} onChange={onChangeSelectPartner}>
                    <option value="">Sem parceiro</option>
                    {partners.map((option) => (
                      <option key={option.uuid} value={option.uuid}>
                        {option.friendlyName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              ''
            )}
            {typeProposal === TypeProposal.PLATFORM && (
              <>
                <div className="form-group col-md-4">
                  <label>Nº da proposta *</label>
                  <input
                    className={`form-control `}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setContractNumber(e.target.value)
                    }
                    value={contractNumber}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Valor *</label>
                  <CurrencyInput
                    className={`form-control`}
                    id="inputValue"
                    onValueChange={(value) => setValue(value!)}
                    value={value}
                    prefix={'R$ '}
                    decimalsLimit={2}
                    decimalSeparator={','}
                    groupSeparator={'.'}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Quantidade de parcelas *</label>
                  <input
                    className={`form-control `}
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setInstallment(e.target.value)
                    }
                    value={installment}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Observações</label>
                  <input
                    className={`form-control `}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setComments(e.target.value)
                    }
                    value={comments}
                  />
                </div>
              </>
            )}
            <div className="form-group col-md-4">
              <label>Protocolo</label>
              <input
                className={`form-control `}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setHuggyChatId(e.target.value)
                }
                value={huggyChatId}
              />
            </div>
            <div className="form-group col-md-4">
              <label>Data da proposta *</label>
              <InputMask
                mask="99/99/9999"
                className={`form-control`}
                autoComplete="off"
                placeholder=""
                value={createdAt}
                onChange={(e: any) => setCreatedAt(e.target.value)}
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-block btn-primary"
            style={{ marginTop: '20px' }}
          >
            {typeProposal === TypeProposal.NORMAL
              ? 'Consultar parcelas'
              : 'Salvar'}
          </button>
        </form>
      </div>
      {statusMessageAlert && (
        <div
          className={
            statusMessageAlert.status === 'error'
              ? 'alert alert-danger alert-text-center'
              : 'alert alert-primary alert-text-center'
          }
          style={{ marginTop: '1rem' }}
          role="alert"
        >
          {statusMessageAlert.message}
        </div>
      )}
    </Layout>
  );
};

export default Create;
